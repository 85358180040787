import React, { useState } from 'react';
import './style.css';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import EyeIcon from '../../../assets/icons/eye-icon.png';
import EyeInvisibleIcon from '../../../assets/icons/eye-invisible-icon.png';
import Feedback from './Feedback';

function Input({
  label = '',
  type = 'text',
  name = 'input-password',
  placeholder = 'Email Address',
  className = '',
  error = null,
  inputRef = null,
  ...rest
}) {
  const [inputType, setInputType] = useState(type);
  const toggleVisibility = () => setInputType(inputType === 'password' ? inputType : 'password');

  return (
    <div className={clsx(className)}>
      <label htmlFor={name} className="relative">
        <input
          {...rest}
          ref={inputRef}
          style={{ width: '100%' }}
          type={inputType}
          placeholder={placeholder}
          id={name}
          name={name}
        />
        {label !== '' && 'Password'}
        {type === 'password' && (
          <button
            type="button"
            className="absolute right-2 top-1/2 translate-y-[-50%] cursor-pointer"
            onClick={toggleVisibility}
          >
            <img
              loading="lazy"
              width={18}
              src={inputType === 'text' ? EyeIcon : EyeInvisibleIcon}
              alt="show password"
            />
          </button>
        )}
      </label>
      <Feedback error={error} />
      {/* error */}
    </div>
  );
}

export default Input;

Input.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  className: PropTypes.string,
};
