import React from 'react';
// assets
import MetaTalkPoster1 from '../../assets/images/meta-talk-poster-1.png';
import MetaTalkPoster2 from '../../assets/images/meta-talk-poster-2.png';
import IRLogo from '../../assets/images/ir-sol.png';
import WideWayStudioLogo from '../../assets/images/wide-way-studio.png';
import YoutubeEmbedded from '../common/YoutubeEmbedded.jsx';
// components
import MetaTvLogo from '../icons/MetaTvLogo';

function MetaTalk() {
  return (
    <section className="py-[60px] w-full 1xl:w-[1400px] px-[20px] sm:px-[40px] lg:px-[80px] mx-auto">
      <div className="text-center">
        <MetaTvLogo />

        <p className="my-[36px] w-full lg:w-3/4 mx-auto">
          Presenting MetaTalk, a show that brings insights, the latest news, and discussions about Metaverse from team
          MetaTor.
        </p>
        <div className="grid grid-cols-1 gap-[18px] lg:gap-[32px] mb-[18px]">
          <YoutubeEmbedded videoId="u97pkoKNuOQ" className="w-full h-[220px] sm:h-[360px] lg:h-[480px] xl:h-[640px]" />
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-8 text-start">
          <div>
            <img loading="lazy" width="100%" src={MetaTalkPoster1} alt="" />
            <p className="text-12px mt-2 opacity-70 font-medium">Episode # 02 - Coming soon</p>
          </div>
          <div>
            <img loading="lazy" width="100%" src={MetaTalkPoster2} alt="" />
            <p className="text-12px mt-2 opacity-70 font-medium">Episode # 03 - Coming soon</p>
          </div>
        </div>

        <div className="lg:w-[680px] mx-[20px] sm:mx-[80px] lg:mx-auto">
          <h3 className="mt-[40px] lg:mt-[60px] mb-[50px] font-bold uppercase font-tertiary text-14px">POWERED BY</h3>
          <div className="flex justify-center items-center">
            <div className="flex-1">
              <img loading="lazy" src={WideWayStudioLogo} alt="Wide way studio" />
            </div>
            <div className="border border-primary mx-[40px] lg:mx-[60px] h-[20px] lg:h-[40px] w-0" />
            <div className="flex-1">
              <img loading="lazy" src={IRLogo} alt="IR Solutions" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default MetaTalk;
