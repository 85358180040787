import React from 'react';
import { Link } from 'react-router-dom';
import MetaverseMeetsEducation from '../../assets/images/case-studies/2/1.jpg';
import DimensionOfEducation from '../../assets/images/case-studies/2/2.jpg';

function CaseStudy2() {
  return (
    <div className="text-14px font-light">
      <p className="mb-4">
        In 2020, when the COVID-19 pandemic was declared, humanity was compelled to live in a world where people weren’t
        able to see each other face-to-face. We witnessed a range of real-world activities transition into the virtual
        world such as the shift to online learning when traditional methods of education proved ineffective in the face
        of such a widespread pandemic. This had a major impact on how we interacted with each other and created a new
        type of society where communication is done through technology.
      </p>

      <p className="mb-4">
        The metaverse, a three-dimensional digital space that incorporates both the real world and the virtual world,
        has been praised as a valuable new educational tool with huge potential.
      </p>

      <h2 className="text-primary font-semibold text-20px mb-2">About Metaverse</h2>

      <img
        loading="lazy"
        className="my-10 mx-auto block"
        src={MetaverseMeetsEducation}
        alt="Metaverse meets education"
      />

      <p className="mb-4">Here are some ways the Metaverse can help improve learning.</p>

      <h2 className="text-primary font-semibold text-20px mb-2">
        1. Learning and interacting in a realistic virtual campus
      </h2>
      <p className="mb-10">
        The metaverse can provide a hugely immersive learning experience, letting students explore the virtual
        university or campus like they’re really there. Immersive technologies provide the foundation for education in
        the metaverse — a virtual reality environment that expands your understanding and perception of the real world.
      </p>

      <h2 className="text-primary font-semibold text-20px mb-2">2. Training future medical professionals</h2>
      <p className="mb-4">
        The opportunity to learn in an encouraging, team-based environment is only one of the numerous advantages of
        attending a medical college in the metaverse. Young professionals can enroll in numerous training programs in
        the metaverse to gain the skills they need to succeed.
      </p>
      <p className="mb-10">
        With VR training, medical students can experience surgery in a safe and controlled environment even before they
        actually become surgical fellows.
      </p>

      <h2 className="text-primary font-semibold text-20px mb-2">3. Business education in the metaverse</h2>
      <p className="mb-4">
        In the metaverse, business education is a critical tool for entrepreneurs and business owners to keep up with
        the latest industry trends and make sound decisions. Classes and workshops are available to help students
        develop the skills they need to be thriving in the business world as well as help aspiring entrepreneurs build
        successful businesses in the metaverse.
      </p>
      <p className="mb-10">
        These programs can teach business concepts, strategies, and tools that can be used in any environment, including
        real-world businesses. The metaverse business schools also provide opportunities to connect with other
        entrepreneurs and learn from their experiences.
      </p>

      <h2 className="text-primary font-semibold text-20px mb-2">
        How does MetaTor contribute to improving the educational system?
      </h2>
      <p className="mb-4">
        The vision behind MetaTor is to make education accessible to everyone in every part of the World. Team MetaTor
        is committed to bringing affordable and smart education to everyone. Moreover, the team MetaTor is aiming to
        create a big network of universities and educational institutes. Every student will be able to get himself
        enrolled in any university or any program to get an education in virtual classrooms in MetaTor. Education is an
        opportunity and
        <Link to="/">
          <u className="mx-2">MetaTor</u>
        </Link>
        is all about creating opportunities,
        <Link to="/experiences">
          <u className="mx-2">immersive experiences</u>
        </Link>
        , and possibilities for everyone in this virtual World.
      </p>

      <img loading="lazy" className="my-10 mx-auto block" src={DimensionOfEducation} alt="Dimension of education" />

      <p className="mb-4">
        Moreover, different kinds of institutes will be set up for technical courses, special classes, or any other
        learning needs to provide opportunities for people to make their skills set and live a better life. All kinds of
        technical instruments and facilities will be provided because team MetaTor won’t compromise when it comes to
        education.
      </p>
    </div>
  );
}

export default CaseStudy2;
