import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
// # assets
import clsx from 'clsx';
import Logo from '../components/icons/Logo';
import LiveTvIcon from '../components/icons/LiveIcon';
import { ReactComponent as HamburgerIcon } from '../assets/icons/hamburger.svg';
import { ReactComponent as CloseIcon } from '../assets/icons/close-icon.svg';
// # components
import SocialLinks from '../components/common/SocialLinks';
import Dropdown, { MobileDropdown } from '../components/common/dropdown/Dropdown';
import useViewportWidth, { useViewportHeight } from '../hooks/useWindow';
import HamburgerAnimatedIcon from '../components/icons/hamburger-animated/HamburgerAnimatedIcon';

function Header() {
  const [open, setOpen] = useState(false);
  const isTablet = useViewportWidth() < 992;
  const isSmallViewport = useViewportHeight() < 726;

  useEffect(() => {
    if (!isTablet && open) {
      setOpen(false);
    }
  }, [isTablet]);

  const showMenu = () => setOpen(!open);

  const navLinks = [
    {
      title: 'Experiences',
      path: '/experiences',
      key: '1',
      icon: true,
      children: [
        {
          title: 'MetaTor Fashion',
          path: '/experiences/fashion',
          key: '1',
          hoverBG: 'purple',
        },
        {
          title: 'MetaTor Shopping',
          path: '/experiences/shopping',
          key: '2',
          hoverBG: 'warning',
        },
        {
          title: 'MetaTor Gaming',
          path: '/experiences/gaming',
          key: '3',
          hoverBG: 'success',
        },
      ],
    },
    {
      title: 'Builder',
      path: '/builder',
      key: '3',
    },
    {
      title: 'Investors',
      path: '/',
      key: '2',
    },
    {
      title: '$MTOR',
      path: '/',
      key: '4',
    },
    {
      title: 'Community',
      path: '/',
      key: '5',
      children: [
        {
          title: 'Case studies',
          path: '/case-studies',
          key: '0',
        },
        {
          title: 'Freelance Community',
          path: '',
          key: '1',
        },
        {
          title: 'Public Forum',
          path: '',
          key: '2',
        },
        {
          title: 'Become a Contributor',
          path: '',
          key: '3',
        },
      ],
    },
    {
      title: <LiveTvIcon className="w-[70px] relative bottom-[6px]" />,
      path: '/',
      key: '6',
    },
  ];

  const updateTheme = () => {
    const randomColor = Math.floor(Math.random() * 16777215).toString(16);
    // change theme color dynamically
    document.documentElement.style.setProperty('--color-primary', `#${randomColor}`);
    document.documentElement.style.setProperty('--color-primary-500', `#${randomColor}50`);
  };

  return (
    <header className="fixed top-0 w-full z-50">
      <div className="flex items-center justify-between bg-secondary px-[16px] md:px-[30px] xl:px-[60px] 1xl:px-[70px] 2xl:px-[95px] h-[100px]">
        <div className="flex items-center">
          <Link to="/">
            <Logo className="md:mr-[90px] lg:mr-[70px] xl:mr-[80px] 1xl:mr-[100px] 3xl:mr-[120px]" />
          </Link>
          <nav className="hidden lg:block">
            <ul className="text-white flex items-center">
              {navLinks.map(({ title, key, children, path, icon }) => (
                <div className="flex items-center [&:last-child>span]:hidden" key={key}>
                  {children ? (
                    <li className="h-[40px] leading-[40px]">
                      <Dropdown items={children} title={title} icon={icon} path={path}>
                        <span>{title}</span>
                      </Dropdown>
                    </li>
                  ) : (
                    <li key={key} className="h-[40px] leading-[40px]">
                      <Link to={path} className="inline-block">
                        <span>{title}</span>
                      </Link>
                    </li>
                  )}
                  <span className="inline-block h-[12px] bg-primary w-0 border-r border-primary sm:mx-[10px] lg:mx-[16px] xl:mx-[20px] 1xl:mx-[30px]" />
                </div>
              ))}
            </ul>
          </nav>
        </div>
        <SocialLinks className="hidden xlg:flex" filterOut />
        <button type="button" className="inline-block lg:hidden" onClick={showMenu}>
          <HamburgerAnimatedIcon isActive={open} />
        </button>
      </div>
      <div className="text-12px bg-primary text-end px-8 py-1 cursor-pointer">
        <button type="button" onClick={updateTheme} className="border-b border-white">
          New Color Surprise
        </button>
      </div>

      {open && (
        <div className="absolute right-0 bg-black w-full animate-fade-up border-b border-primary">
          <nav className="mt-5">
            <ul
              className={clsx(
                'text-white flex flex-col items-center overflow-y-auto',
                isSmallViewport ? '!h-[calc(100vh-140px)]' : 'h-max'
              )}
            >
              {navLinks.map(({ title, key, path, children }) => (
                <div
                  className="flex flex-col items-center gap-y-5 [&:last-child>span]:hidden [&:last-child]:mb-5"
                  key={key}
                >
                  {children ? (
                    <MobileDropdown
                      handleDropdownItemClicked={() => setOpen(false)}
                      items={children}
                      title={title}
                      path={path}
                      showMenu={showMenu}
                    />
                  ) : (
                    <li className="h-[40px] leading-[40px] hover:text-primary" key={key}>
                      <Link to={path} onClick={showMenu}>
                        <span>{title}</span>
                      </Link>
                    </li>
                  )}
                  <span className="inline-block h-0 w-5 border border-primary sm:mx-[10px] lg:mx-[16px] xl:mx-[20px] 1xl:mx-[30px] my-3" />
                </div>
              ))}
            </ul>
          </nav>
        </div>
      )}
    </header>
  );
}

export default Header;
