import React from 'react';
import clsx from 'clsx';
import PrimaryCard from '../common/PrimaryCard';
import PrimarySlickSlider from '../common/PrimarySlickSlider';
import MetatorIco from '../../assets/icons/metator-ico.png';
import { getAssetsDynamically } from '../../utils/getAssetsDynamically';
import { getDashedURL } from '../../utils/getDashedURL';

function Explore({ data = [] }) {
  return (
    <section className="mb-[40px] lg:mb-[90px] lg:w-[750px] xl:w-[1000px] 1xl:w-[1200px] 2xl:w-[1350px] 3xl:w-[1650px] mx-[20px] sm:mx-[30px] lg:mx-auto">
      <div className="text-center">
        <h3 className="uppercase text-20px font-black mt-4 mb-3">EXPLORE MORE CASE STUDIES</h3>
        <p className="w-full lg:w-1/2 mx-auto mb-[30px] lg:mb-[60px]">
          Read more of what is happening in the Metator World!
        </p>

        <div className="primary-slick-cards text-start">
          <PrimarySlickSlider slides={2} color="primary" mobileSlides={1} autoplay autoplaySpeed={4000}>
            {data.map(({ description, title, poster, key, containerClassName, extraContent }) => (
              <div key={key} className="p-3">
                <PrimaryCard
                  key={key}
                  containerClassName={clsx('mb-16 lg:mb-0', containerClassName)}
                  extraContent={extraContent}
                  description={description}
                  primaryTitle={title}
                  poster={getAssetsDynamically(poster, 'images/case-studies')}
                  className="min-h-[220px] sm:min-h-[380px]"
                  variant="secondary"
                  ico={MetatorIco}
                  URL={`/case-studies/${key}/${getDashedURL(title)}`}
                />
              </div>
            ))}
          </PrimarySlickSlider>
        </div>
      </div>
    </section>
  );
}

export default Explore;
