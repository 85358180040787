// # ENUMS

// # GLOBAL RESOURCES

// metator services
export const METATOR_SERVICES = [
  {
    title: 'SHOPPING',
    description:
      'Shopping in MetaTor empowers every customer to shop with freedom and get goods delivered to their doorstep without waiting in long queues.',
    key: 'shopping',
    color: 'warning',
    poster: 'metator-shopping.png',
  },
  {
    title: 'FASHION',
    description:
      'Enter into the world of glamour and fashion to embrace new art and designs collection of the industry.',
    key: 'fashion',
    color: 'purple',
    poster: 'metator-fashion.png',
  },
  {
    title: 'GAMING',
    description: "Gaming is going to be interactive and full of player's engagement in the virtual world of MetaTor.",
    key: 'gaming',
    color: 'success',
    poster: 'metator-gaming.png',
  },
  {
    title: 'LIFESTYLE',
    description:
      'Embrace technology and live a standard lifestyle where smart solutions lead to making better decisions in your life.',
    key: 'lifestyle',
    color: 'warning-800',
    poster: 'metator-lifestyle.png',
    isComingSoon: true,
  },
  {
    title: 'EDUTAINMENT',
    description:
      'Spreading education is about to become more active and efficient with an interactive education system being introduced in MetaTor.',
    key: 'edutainment',
    color: 'danger',
    poster: 'metator-edutainment.png',
    isComingSoon: true,
  },
  {
    title: 'BUSINESS',
    description:
      'Revolutionizing the landscape of business,  providing a variety of opportunities and business setup options for everyone.',
    key: 'business',
    color: 'info',
    poster: 'metator-business.png',
    isComingSoon: true,
  },
];

// experience use-cases
export const USE_CASES = [
  {
    title: 'Search for Stores',
    description: 'Metator Shopping has multiple use cases.',
    key: 'search',
  },

  {
    title: 'Try out products',
    description: 'Try out products in real time so you can make am effective purchase decision.',
    key: 'try',
  },
  {
    title: 'Pay in the Metaverse',
    description: 'Use your native currency or metator pay to make transactions fluidly.',
    key: 'pay',
  },
  {
    title: 'Get it at your doorstep',
    description: 'The connected store will deliver your goods at your doorstep.',
    key: 'get',
  },
];
