import React from 'react';
// # assets
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import Button from '../components/common/button/Button';
// # components
import PrimaryCard from '../components/common/PrimaryCard';

import MetaTags from '../components/common/MetaTags';
import { ReactComponent as RightOutlined } from '../assets/icons/right-outlined.svg';
import BreadCrumb from '../components/case-studies/Breadcumb';
import CASE_STUDIES from '../data/case-studies.json';
import { getAssetsDynamically } from '../utils/getAssetsDynamically';
import CaseStudy1Poster from '../assets/images/case-studies/case-study-2.jpg';
import { getDashedURL } from '../utils/getDashedURL';

const featuredCaseStudyURL =
  '/case-studies/2/How-does-the-Metaverse-help-improve-the-current-system-of-education-&-learning';

function CaseStudies() {
  return (
    <div className="mb-[180px]">
      <MetaTags page="case-studies" />
      <BreadCrumb className="w-5/6 2xl:w-[1440px] mx-auto" title="Metator Case Studies" />

      <Link to={featuredCaseStudyURL}>
        <section className="w-5/6 2xl:w-[1440px] mx-auto">
          <div className="relative text-center">
            <img loading="lazy" width="100%" src={CaseStudy1Poster} alt="featured case study" className="mx-auto" />
            <div className="absolute w-full h-full top-0 gradient-from-bottom-50" />
          </div>
          <div className="flex items-end justify-center">
            <div>
              <h1 className="uppercase font-bold mb-[14px] text-24px lg:text-32px">
                How does the Metaverse help improve the current system of education & learning?
              </h1>
              <p className="text-18px mb-4 relative">
                In 2020, when the COVID-19 pandemic was declared, humanity was compelled to live in a world where people
                weren’t able to see each other face-to-face.
                <div className="absolute w-full h-full top-0 gradient-from-bottom" />
              </p>
              <div className="flex justify-between items-center text-14px">
                <p className="font-light text-gray">November 22, 2022</p>

                <Button type="text" title="Read more" suffix={<RightOutlined className="[&>path]:stroke-primary" />} />
              </div>
            </div>
          </div>
        </section>
      </Link>

      <section className="w-5/6 2xl:w-[1440px] mt-[90px] mx-auto lg:flex justify-between items-center flex-wrap gap-y-20">
        {CASE_STUDIES.map(({ title, description, extraContent, containerClassName, poster, key, type }) =>
          type === 'divider' ? (
            <div className="hidden lg:block h-[120px] border border-white opacity-50" key={key} />
          ) : (
            <PrimaryCard
              key={key}
              containerClassName={clsx('mb-16 lg:mb-0 lg:w-[47%]', containerClassName)}
              extraContent={extraContent}
              description={description}
              primaryTitle={title}
              poster={getAssetsDynamically(poster, 'images/case-studies')}
              className="min-h-[220px] sm:min-h-[380px]"
              variant="secondary"
              URL={`/case-studies/${key}/${getDashedURL(title)}`}
            />
          )
        )}
      </section>
    </div>
  );
}

export default CaseStudies;
